import GymInsideSmall from '../assets/images/machine-room13-600w.avif'
import GymInsideLarge from '../assets/images/free-weight-room12-600w.avif'
import GymInsideMedium from '../assets/images/machine-room7-600w.avif'
import Machines from '../assets/images/cardio-room17-600w.avif'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import MembershipPackage from '../components/MembershipPackage';
import { IMembershipPackage, membershipPackages } from '../assets/membership-options';
import RegistrationForm from '../components/RegistrationForm';
import { scrollToSection } from '../utils/ScrollToSection';
import HeadingOne from '../components/HeadingOne';

const MembershipPage = () => {
    useEffect(() => {
        document.title = `Kies uw Lidmaatschap - Private Gym Schijndel`;
    }, []);
    const [selectedMembership, setSelectedMembership] = useState<IMembershipPackage | null>(null);

    const selectionRef = useRef<null | HTMLDivElement>(null);
    const packagesRef = useRef<null | HTMLDivElement>(null);

    return (
        <>
            <section className="hero membership-hero">
                <div className="default-container flex justify-center items-center h-full w-full">
                    <div className="text-center">
                        <HeadingOne>Kies wat het best bij u past.</HeadingOne>
                        <h3 className="text-primary-500 pt-2 pb-16">Kies het pakket dat het best bij u aansluit.</h3>
                    </div>
                </div>
            </section>

            <section id="membership-options-section" ref={packagesRef}
                     className="exercise-types bg-gradient-to-b from-primary-800 via-primary-400 to-white pb-20 md:pb-32 md:pt-4">
                <div className="medium-container flex flex-col justify-center items-center w-full">
                    <h2 className="text-3xl md:text-5xl tracking-wider pb-16 md:pb-32 pt-16 text-white">Een pakket
                        speciaal voor
                        u.</h2>
                    <div className="w-full block md:flex space-y-4 md:space-y-0 space-x-0 md:space-x-8 mb-0">
                        {membershipPackages.map(membership => {
                            return <MembershipPackage key={membership.id} membership={membership}
                                                      setSelectedMembership={setSelectedMembership}
                                                      selectionRef={selectionRef}/>
                        })}
                    </div>
                </div>
            </section>

            {/*<section className="bg-black text-center sticky bottom-0 z-20">*/}
            {/*    <div className="mx-auto max-w-screen-xl px-8 flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-4 md:space-y-0*/}
            {/*     justify-center items-center text-white py-4 ">*/}
            {/*        <h4 className="text-sm md:text-xl uppercase">Feestelijke Opening: De huidige prijzen gelden tot 1*/}
            {/*            januari 2024</h4>*/}
            {/*        <button onClick={() => scrollToSection(packagesRef)} className="secondary-button text-sm">Kies*/}
            {/*            pakket*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {selectedMembership && (
                <section
                    ref={selectionRef}
                    className={`bg-gray-100 expandable-section ${selectedMembership ? 'expanded' : ''}`}
                >
                    <div className="default-container">
                        <h3>Uw gekozen pakket.</h3>
                        <div
                            className="flex flex-col md:flex-row pt-12 space-x-0 md:space-x-8 xl:space-x-16 space-y-12 md:space-y-0">
                            <div className="w-full flex flex-col space-y-8 md:w-1/2 xl:w-1/3">
                                <MembershipPackage membership={selectedMembership} isSelected={true}/>
                                <button onClick={() => {
                                    scrollToSection(packagesRef);
                                    setSelectedMembership(null);
                                }} className="secondary-button">Wijzig
                                    pakketkeuze
                                </button>
                            </div>
                            <div className="w-full md:w-1/2 xl:w-2/3">
                                <h4>Vul uw gegevens in voor een intakegesprek.</h4>
                                <h5 className="text-gray-400 pb-8">Een intakegesprek is altijd vrijblijvend.</h5>
                                <RegistrationForm selectedMembership={selectedMembership}/>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/*TODO section zo maken dat het gaat over trainingsschema inbegrepen en onbeperkt sporten*/}
            {/*<section className="pt-12">*/}
            {/*    <div className="default-container">*/}
            {/*        <h2>Ruimtes gebruiken voor Personal Training.</h2>*/}
            {/*        <div*/}
            {/*            className="flex-col md:flex-row space-y-8 md:space-y-0 flex pt-8 space-x-0 md:space-x-12 items-center">*/}
            {/*            <div>*/}
            {/*                <p className="pb-8">Ontdek onze geavanceerde cardioruimte, waar je je conditie naar nieuwe*/}
            {/*                    hoogten kunt*/}
            {/*                    stuwen. Met moderne cardio-apparaten bereik je moeiteloos je fitnessdoelen en werk je*/}
            {/*                    aan een sterker, energieker lichaam. Ontdek onze geavanceerde cardioruimte, waar je je*/}
            {/*                    conditie naar nieuwe hoogten kunt stuwen.</p>*/}
            {/*                <Link to={'/contact'}>*/}
            {/*                    <button className="primary-button">Vraag een gesprek aan</button>*/}
            {/*                </Link>*/}
            {/*            </div>*/}
            {/*            <div className="h-64 w-full shadow-2xl rounded zoom">*/}
            {/*                <img className="object-cover w-full h-full" src={GymInsideMedium} alt="TODO"/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="bg-black">
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2 className="text-white">Onduidelijkheden? Vraag het ons.</h2>
                    <p className="text-gray-300 pt-4 pb-12">Neem direct contact op als u verdere vragen heeft.</p>
                    <Link to={'/contact'} className="primary-button">Neem contact op</Link>
                </div>
            </section>

            <section className="bg-gray-100 py-8 md:py-24">
                <div className="small-container">
                    <div
                        className="flex flex-col md:flex-row items-center space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                        <div className="shadow-2xl rounded zoom h-80 w-full">
                            <img className="object-cover w-full h-full" src={GymInsideLarge} alt="Free weight gewichten"
                                 loading="lazy"/>
                        </div>
                        <div className="flex flex-col space-y-4 w-full">
                            <div className="shadow-2xl rounded zoom h-48">
                                <img className="object-cover w-full h-full" src={GymInsideMedium}
                                     alt="Chest press machine" loading="lazy"/>
                            </div>
                            <div className="shadow-2xl rounded zoom h-48">
                                <img className="object-cover w-full h-full" src={GymInsideSmall} alt="Machine"
                                     loading="lazy"/>
                            </div>
                        </div>
                        <div className="shadow-2xl rounded zoom h-80 w-full">
                            <img className="object-cover w-full h-full" src={Machines} alt="Loopband" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MembershipPage;