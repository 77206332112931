import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as GymLogo } from '../assets/images/Privategym_schijndel_logo.svg';

const Footer = () => {
    return (
        <section className="footer pb-20">
            <div className="default-container">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-8 md:gap-y-0">
                    <div className="flex flex-col space-y-2">
                        <Link to={''}><GymLogo
                            className="h-full w-full md:w-2/3 transition-colors hover:fill-primary-300"/></Link>
                        <hr className="primary-hr"/>
                        <div>Copyright © 2023</div>
                        <div
                            className="xl:w-3/5 pt-4 grid grid-cols-2 md:grid-cols-4 gap-y-8 md:gap-y-0 text-3xl text-primary-500">
                            <Link to={''}>{<FontAwesomeIcon icon={faInstagram}/>}</Link>
                            <Link to={''}>{<FontAwesomeIcon icon={faFacebook}/>}</Link>
                            <Link to={''}>{<FontAwesomeIcon icon={faLinkedin}/>}</Link>
                            <Link to={'https://wa.me/+31683371313'}>{<FontAwesomeIcon icon={faWhatsapp}/>}</Link>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <h4 className="pb-4">Navigeer direct</h4>
                        <Link to={'/concept'}>Over Private Gym Schijndel</Link>
                        <Link to={'/rooms'}>Onze ruimtes</Link>
                        <Link to={'/membership'}>Kies een lidmaatschap</Link>
                        <Link to={'/contact'}>Direct contact opnemen</Link>
                        <Link to={'/faq'}>Vaak gestelde vragen</Link>
                    </div>

                    <div className="flex flex-col space-y-2 col-span-2 md:col-span-1">
                        <h4 className="pb-4">Neem contact op</h4>
                        <div><span className="text-primary-500 pr-2">{<FontAwesomeIcon
                            icon={faLocation}/>}</span> Ericastraat 3, Schijndel, 5482
                            WR
                        </div>
                        <a href="tel:+31683371313"><span
                            className="text-primary-500 pr-2">{<FontAwesomeIcon icon={faPhone}/>}</span> +31 6 8337 1313</a>
                        <a href="mailto:info@privategymschijndel.com"><span
                            className="text-primary-500 pr-2">{<FontAwesomeIcon
                            icon={faEnvelope}/>}</span> info@privategymschijndel.com</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;