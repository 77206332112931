export const scrollToSection = (ref: any) => {
    if (ref.current) {
        ref?.current.scrollIntoView({behavior: 'smooth'});
    }
};

export const goToMemberships = (navigate: any) => {
    navigate('/membership')
    setTimeout(() => {
        const membershipOptionsSection = document.getElementById('membership-options-section')
        if (membershipOptionsSection) {
            membershipOptionsSection.scrollIntoView({behavior: 'smooth'});
        }
    }, 0)
}