import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './App.css';
import HeaderBar from './components/HeaderBar';
import { Outlet } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import Footer from './components/Footer';
import WhatsappButton from './components/WhatsappButton';

function App() {
    return (
        <>
            <ScrollToTop/>
            <HeaderBar/>
            <Outlet/>
            <Footer/>
            <WhatsappButton/>
        </>
    )
}

export default App;
