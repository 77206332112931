import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { ReactComponent as GymLogo } from '../assets/images/Privategym_schijndel_logo.svg';
import { goToMemberships } from '../utils/ScrollToSection';

const HeaderBar = () => {
    const [isTop, setIsTop] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuIcon = <FontAwesomeIcon icon={isMenuOpen ? faX : faBars}/>;
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleScroll = () => {
        setIsTop(window.scrollY <= 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const location = useLocation();

    return (
        <nav id="main-header"
             className={`h-auto xl:h-20 w-full transition-all fixed top-0 z-50 ${isTop && !isMenuOpen ? 'bg-transparent' : 'bg-black'}`}>
            <div className="mx-auto max-w-screen-xl h-full py-4 xl:py-0 px-4">
                <div
                    className={`uppercase flex justify-between items-center h-full text-white ${isMenuOpen ? 'flex-col' : 'flex-row'}`}>
                    <div className="h-full w-2/5 xl:w-auto py-0 xl:py-4">
                        <a href="/" className={` ${isMenuOpen ? 'mb-2 flex justify-center items-center' : ''}`}>
                            <GymLogo
                                className="max-h-12 xl:max-h-full h-full transition-colors hover:fill-primary-300"/>
                        </a>
                    </div>
                    <button
                        className={`xl:hidden mx-4 text-white ${isMenuOpen ? 'absolute top-5 right-4' : ''}`}
                        onClick={toggleMenu}
                    >
                        {menuIcon}
                    </button>
                    <div
                        className={`header-menu py-4 font-semibold xl:py-0 flex ${isMenuOpen ? 'flex-col items-center space-y-4' : 'hidden xl:flex'} xl:space-x-6 xl:space-y-0`}>
                        <Link to="/concept" onClick={closeMenu}
                              className={`header-menu-item ${location.pathname === '/concept' ? 'active-link' : ''}`}>Het
                            Concept</Link>
                        <Link to="/membership" onClick={closeMenu}
                              className={`header-menu-item ${location.pathname === '/membership' ? 'active-link' : ''}`}>Lidmaatschap</Link>
                        <Link to="/rooms" onClick={closeMenu}
                              className={`header-menu-item ${location.pathname === '/rooms' ? 'active-link' : ''}`}>De
                            Ruimtes</Link>
                        <Link to="/faq" onClick={closeMenu}
                              className={`header-menu-item ${location.pathname === '/faq' ? 'active-link' : ''}`}>FAQ</Link>
                        <Link to="/pictures" onClick={closeMenu}
                              className={`header-menu-item ${location.pathname === '/pictures' ? 'active-link' : ''}`}>Foto's</Link>

                    </div>
                    <div
                        className={`flex font-semibold ${isMenuOpen ? 'flex-col items-center space-y-4' : 'hidden xl:flex'} xl:space-x-4 xl:space-y-0 justify-center items-center`}>
                        <Link className={`secondary-button ${location.pathname === '/contact' ? 'active-link' : ''}`}
                              to="/contact"
                              onClick={closeMenu}>
                            Contact opnemen
                        </Link>
                        <button className="primary-button" onClick={() => {
                            goToMemberships(navigate);
                            closeMenu()
                        }}>Direct inschrijven
                        </button>
                    </div>
                </div>
            </div>
            {!isTop && <ProgressBar/>}
        </nav>
    );
};

export default HeaderBar;
