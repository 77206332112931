import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JSConfetti from 'js-confetti';

const SuccessPage = () => {
    useEffect(() => {
        document.title = `Uw inschrijving - Private Gym Schijndel`;
    }, []);

    const jsConfetti = new JSConfetti()
    const location = useLocation();
    const navigate = useNavigate();
    const isFormSubmitted = location.state?.isFormSubmitted;


    useEffect(() => {
        if (!isFormSubmitted) {
            navigate('/membership');
        } else {
            jsConfetti.addConfetti();
        }
    }, []);

    return (
        <>
            <section className="flex h-screen items-center justify-center w-full">
                <div className="half-bg flex-1"></div>

                <div className="flex-1 h-full bg-black flex items-center">
                    <div className="max-w-2xl px-12">
                        <h2 className="text-white">
                            Uw aanvraag is succesvol doorgevoerd!
                        </h2>
                        <hr className="primary-hr"/>
                        {/* <hr className="hero-hr" /> */}
                        <p className="text-gray-200 pt-4">De aanvraag is binnen. We komen zo spoedig
                            mogelijk bij u in de lucht voor een gesprek.</p>
                    </div>
                </div>
            </section>

            <section>
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2>Kunt u niet wachten?</h2>
                    <p className="pt-4 pb-12">Geniet dan nog even van de foto's tot u dit in het echt kunt
                        ervaren.</p>
                    <Link className="primary-button" to={'/pictures'}>Bekijk alle foto's</Link>
                </div>
            </section>
        </>
    )
}

export default SuccessPage;