import React, { ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

interface HeadingOneProps {
    children: ReactNode;
}

const HeadingOne: React.FC<HeadingOneProps> = ({children}) => {
    const fadeIn = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        config: {duration: 1000}, // Adjust the duration as needed
    });

    return (
        <animated.h1 style={fadeIn}>{children}</animated.h1>
    );
};

export default HeadingOne;
