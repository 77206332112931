import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const WhatsappButton = () => {
    return (
        <a href="https://wa.me/+31683371313" target="_blank" rel="noreferrer">
            <div className="contact-float-button pulse">
                {<FontAwesomeIcon icon={faWhatsapp}/>}
            </div>
        </a>
    )
}

export default WhatsappButton;