import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const percentage = (scrollY / (documentHeight - windowHeight)) * 100;
        setScrollPercentage(percentage);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="w-full h-1.5 relative bg-white transition-transform">
            <div className="h-full absolute" style={{width: `${scrollPercentage}%`}}>
                <div className="h-full primary-gradient"></div>
            </div>
        </div>
    );
};

export default ProgressBar;
