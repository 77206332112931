import React from 'react';

const MembershipPackage = (props: any) => {
    const {membership, setSelectedMembership, selectionRef, isSelected, isHomePage} = props;

    const scrollToSection = () => {
        setTimeout(() => {
            if (selectionRef.current) {
                selectionRef?.current.scrollIntoView({behavior: 'smooth'});
            }
        }, 0);
    };

    return (
        <div
            className={`w-full flex flex-col justify-between 
            ${isHomePage ? 'black-card text-white' : 'white-card'}
    ${membership.isAdvised ? 'border-2 border-solid border-primary-200' : ''}
    ${!isSelected && membership.isAdvised ? 'complete-bundle' : 'bundle-card'}
  `}
        >
            {membership.isAdvised ? <div>
                    <h4 className="md:text-3xl font-semibold">{membership.title}</h4>
                    <hr className="primary-hr"/>
                    <div className="text-sm text-primary-500 pt-1">Geadviseerd</div>
                </div> :
                <h4>{membership.title}</h4>}
            {/* <p className="text-gray-200 pt-4">Ontdek onze geavanceerde cardioruimte, waar je je conditie naar nieuwe hoogten kunt stuwen. Met moderne cardio-apparaten bereik je moeiteloos je fitnessdoelen en werk je aan een sterker, energieker lichaam.</p> */}
            <ul className="package-list py-4">
                {membership.benefits.map((benefit: string) => {
                    return <li className={isHomePage ? 'text-gray-200' : 'text-gray-500'} key={benefit}>{benefit}</li>
                })}
            </ul>

            <div>
                <div className="py-2"><span
                    className="line-through text-gray-500 pr-2 md:pr-1 text-sm">€{membership.price}</span><span
                    className="text-3xl">€{membership.discountPrice}</span> /
                    volledige maand
                </div>
                {!isSelected && !isHomePage && <button onClick={() => {
                    setSelectedMembership(membership);
                    scrollToSection();
                }} className="primary-button">Kies&nbsp;
                    {membership.title}
                </button>}

            </div>
        </div>
    )
}

export default MembershipPackage;