import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { animated, useSpring } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
    const [inputValueFullName, setInputValueFullName] = useState('');
    const [inputValueEmail, setInputValueEmail] = useState('');
    const [inputValueMessage, setInputValueMessage] = useState('');
    const [hasValidationErrors, setHasValidationErrors] = useState({
        fullName: false,
        email: false,
        message: false,
    });
    const [wasSentSuccessfully, setWasSentSuccessfully] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (wasSentSuccessfully) {
            setSlideIn({opacity: 1, bottom: 20});


            setTimeout(() => {
                setFadeOut({opacity: 0});
                setTimeout(() => {
                    setWasSentSuccessfully(false);
                }, 500); // Adjust the duration to match your CSS transition
            }, 3500); // Adjust the delay as needed
        }
    }, [wasSentSuccessfully]);

    const [slideIn, setSlideIn] = useSpring(() => ({
        opacity: 0,
        bottom: -100,
    }));

    const [fadeOut, setFadeOut] = useSpring(() => ({
        opacity: 1,
    }));

    const handleSubmit = async (e: any) => {

        e.preventDefault();

        emailjs.init('1H_M1wONOhR8QK7dJ');

        const validationErrors = {
            fullName: !inputValueFullName,
            email: !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                inputValueEmail,
            ),
            message: !inputValueMessage,
        };

        setHasValidationErrors(validationErrors);

        if (!inputValueFullName || !inputValueEmail || !inputValueMessage) {
            console.log('Please fill out all the required fields.');
            return;
        }

        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(inputValueEmail)) {
            console.log('Please enter a valid email address.');
            return;
        }

        setIsLoading(true);

        // Send the email using emailjs
        try {
            await emailjs.send(
                'service_eqed7ci', // Replace with your service ID
                'template_84vdrle', // Replace with your template ID
                {
                    to_name: 'Private Gym Schijndel',
                    from_name: inputValueFullName,
                    message: inputValueMessage,
                    reply_to: inputValueEmail,
                },
                '1H_M1wONOhR8QK7dJ', // Replace with your user ID
            );

            setInputValueEmail('');
            setInputValueFullName('');
            setInputValueMessage('');
            setWasSentSuccessfully(true);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error sending email:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3 md:mb-0">
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-full-name"
                    >
                        Volledige Naam
                    </label>
                    <input
                        className={`${
                            hasValidationErrors.fullName
                                ? 'border-red-500'
                                : 'border-gray-200'
                        } appearance-none block w-full bg-gray-50 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-primary-500`}
                        id="grid-full-name"
                        type="text"
                        value={inputValueFullName}
                        onChange={(e) => setInputValueFullName(e.target.value)}
                    />
                    {hasValidationErrors.fullName && (
                        <p className="text-red-500 text-xs italic">
                            Voer alstublieft uw naam in
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                    >
                        E-mail
                    </label>
                    <input
                        className={`${
                            hasValidationErrors.email ? 'border-red-500' : 'border-gray-200'
                        } appearance-none block w-full bg-gray-50 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-primary-500`}
                        id="email"
                        type="email"
                        value={inputValueEmail}
                        onChange={(e) => setInputValueEmail(e.target.value)}
                    />
                    {hasValidationErrors.email && (
                        <p className="text-red-500 text-xs italic">
                            Voer alstublieft een geldig e-mailadres in.
                        </p>
                    )}
                    <p className="text-gray-600 text-xs italic">
                        We komen zo spoedig mogelijk bij u in de lucht
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-message"
                    >
                        Bericht
                    </label>
                    <textarea
                        className={`${
                            hasValidationErrors.message ? 'border-red-500' : 'border-gray-200'
                        } no-resize appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-primary-500 h-48 resize-none`}
                        id="message"
                        value={inputValueMessage}
                        onChange={(e) => setInputValueMessage(e.target.value)}
                    ></textarea>
                    {hasValidationErrors.message && (
                        <p className="text-red-500 text-xs italic">
                            Voer alstublieft een vraag/verzoek in
                        </p>
                    )}
                    <p className="text-gray-400 text-xs">
                        Een gesprek is altijd vrijblijvend
                    </p>
                </div>
            </div>


            <div className="flex space-x-4 items-center">
                <button type="submit" className="primary-button">
                    Verstuur bericht
                </button>
                {isLoading && <div>{<FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-primary-500"
                                                     style={{fontSize: '24px'}}/>
                }</div>}

            </div>
            {wasSentSuccessfully && (
                <animated.div
                    className={`form-success-popup`}
                    style={{...slideIn, ...fadeOut}}
                >
                    <div className="form-success-popup-inner">
                        Bericht succesvol verzonden
                    </div>
                </animated.div>
            )}
        </form>
    );
}

export default ContactForm;
