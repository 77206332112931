import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faFile, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef } from 'react'
import ContactForm from '../components/ContactForm';
import HeadingOne from '../components/HeadingOne';
import { scrollToSection } from '../utils/ScrollToSection';
import { Link } from 'react-router-dom';

const ContactPage = () => {
    const contactRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        document.title = `Contact opnemen - Private Gym Schijndel`;
    }, []);

    return (
        <>

            <section className="contact-hero bg-black pt-20">
                <div ref={contactRef} className="default-container">
                    <div className="pb-24 pt-12">
                        <HeadingOne>Direct contact opnemen</HeadingOne>
                    </div>
                    <div className="space-x-0 md:space-x-8 flex flex-col-reverse md:flex-row">
                        <div className="flex-1">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8239.512247974246!2d5.409394144554073!3d51.632574188080845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6e67d3954add3%3A0xcc7c82a7b0d91cbb!2sEricastraat%203%2C%205482%20WR%20Schijndel%2C%20Nederland!5e0!3m2!1snl!2smx!4v1692849245813!5m2!1snl!2smx"
                                width="100%" height="100%" frameBorder={0}
                                style={{border: 0}}
                                allowFullScreen
                                loading="lazy"
                                className="rounded"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="flex-1 pb-8 md:pb-0 flex flex-col justify-center items-center">
                            <div className="max-w-xl mx-auto w-full">
                                <div className="white-card max-w-xl mx-auto">
                                    <ContactForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="pt-12 flex flex-col md:flex-row space-x-0 md:space-x-8 space-y-4 md:space-y-0 items-start md:items-center justify-center w-full text-white">
                        <div><span className="text-primary-500 pr-2">{<FontAwesomeIcon
                            icon={faLocation}/>}</span> Ericastraat 3, Schijndel, 5482
                            WR
                        </div>
                        <a href="tel:+31683371313"><span
                            className="text-primary-500 pr-2">{<FontAwesomeIcon icon={faPhone}/>}</span> +31 6 8337 1313</a>
                        <a href="mailto:info@privategymschijndel.com"><span
                            className="text-primary-500 pr-2">{<FontAwesomeIcon
                            icon={faEnvelope}/>}</span> info@privategymschijndel.com</a>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="small-container text-center">
                    <h2>Andere contactopties.</h2>

                    <div className="bg-white shadow-2xl rounded flex flex-col mt-12">
                        <div
                            className="sub-menu grid md:grid-cols-4 grid-cols-2 justify-between text-primary-500 text-2xl">
                            <Link to={'tel:+31683371313'} target={'_blank'}>
                                <div className="text-4xl">{<FontAwesomeIcon icon={faPhone}/>}</div>
                                <div className="text-sm pt-2 text-gray-400">Bel direct</div>
                            </Link>

                            <Link to={'mailto:info@privategymschijndel.nl'} target={'_blank'}>
                                <div className="text-4xl">{<FontAwesomeIcon icon={faEnvelope}/>}</div>
                                <div className="text-sm pt-2 text-gray-400">Stuur een email</div>
                            </Link>

                            <Link to={'https://wa.me/+31683371313'} target={'_blank'}>
                                <div className="text-4xl">{<FontAwesomeIcon icon={faWhatsapp}/>}</div>
                                <div className="text-sm pt-2 text-gray-400">WhatsApp</div>
                            </Link>

                            <a onClick={() => scrollToSection(contactRef)}>
                                <div className="text-3xl">{<FontAwesomeIcon icon={faFile}/>}</div>
                                <div className="text-sm pt-2 text-gray-400">Vul het formulier in</div>
                            </a>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ContactPage;