import FreeWeight1 from '../assets/images/free-weight-room1-800w.avif'
import FreeWeight2 from '../assets/images/free-weight-room2-800w.avif'
import FreeWeight3 from '../assets/images/free-weight-room3-800w.avif'
import FreeWeight4 from '../assets/images/free-weight-room16-800w.avif'


import Machine1 from '../assets/images/machine-room1-800w.avif'
import Machine2 from '../assets/images/machine-room4-800w.avif'
import Machine3 from '../assets/images/machine-room3-800w.avif'
import Machine4 from '../assets/images/machine-room22-800w.avif'

import Cardio1 from '../assets/images/cardio-room1-800w.avif'
import Cardio2 from '../assets/images/cardio-room2-800w.avif'
import Cardio3 from '../assets/images/cardio-room3-800w.avif'

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import HeadingOne from '../components/HeadingOne';

const PicturesPage = () => {
    useEffect(() => {
        document.title = `Foto's van de Gym - Private Gym Schijndel`;
    }, []);
    const allImages = [FreeWeight1, Machine1, Cardio1, FreeWeight2, Machine2, Cardio2, Cardio3, Machine3, FreeWeight3, Machine4, FreeWeight4];
    const crossIcon = <FontAwesomeIcon icon={faX}/>
    const linkedInIcon = <FontAwesomeIcon icon={faLinkedin}/>
    const instagramIcon = <FontAwesomeIcon icon={faInstagram}/>
    const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp}/>
    const facebookIcon = <FontAwesomeIcon icon={faFacebook}/>

    const [activeImageUrl, setActiveImageUrl] = useState('');
    const showImage = (imageUrl: string) => {
        setActiveImageUrl(imageUrl);
    }
    return (
        <>
            <section className="pictures-hero">
                <div className="default-container flex justify-center items-center h-full w-full">
                    <div className="text-center">
                        <HeadingOne>
                            Foto's Private Gym Schijndel.
                        </HeadingOne>
                        <h3 className="text-primary-500 pt-2 pb-16">Neem een kijkje naar de sportschool.</h3>
                    </div>
                </div>
            </section>

            <section className="pictues-section bg-gray-100">
                <div className="default-container">
                    <div className="max-w-4xl mx-auto grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                        {allImages.map(image =>
                            <div key={image} onClick={() => showImage(image)} className="cursor-pointer h-72 w-full">
                                <img className="object-cover h-full w-full shadow-2xl rounded zoom" src={image}
                                     alt="Private Gym Schijndel afbeelding" loading="lazy"/>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="">
                <div className="small-container">
                    <h2 className="text-center">Bekijk ook onze socials.</h2>

                    <div className="bg-white shadow-2xl rounded flex flex-col mt-12">
                        <div
                            className="sub-menu grid md:grid-cols-4 grid-cols-2 justify-between text-primary-500 text-2xl">
                            <button>
                                <div className="text-4xl">{instagramIcon}</div>
                                <div className="text-sm pt-2 text-gray-400">Instagram</div>
                            </button>

                            <button>
                                <div className="text-4xl">{facebookIcon}</div>
                                <div className="text-sm pt-2 text-gray-400">Facebook</div>
                            </button>

                            <button>
                                <div className="text-4xl">{linkedInIcon}</div>
                                <div className="text-sm pt-2 text-gray-400">LinkedIn</div>
                            </button>

                            <button>
                                <div className="text-3xl">{whatsappIcon}</div>
                                <div className="text-sm pt-2 text-gray-400">WhatsApp</div>
                            </button>

                        </div>
                    </div>
                </div>
            </section>

            {activeImageUrl &&
                <div onClick={() => setActiveImageUrl('')} className="highlight-picture-overlay">
                    <div className="overlay-cross">{crossIcon}</div>
                    <div className="flex items-center justify-center h-screen">
                        <img
                            src={activeImageUrl}
                            alt="Your Image"
                            className="max-h-[80vh] max-w-[90vw] w-auto"
                        />
                    </div>
                </div>
            }

        </>
    )
}

export default PicturesPage;