import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/helper-pages/ErrorPage';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import RoomsPage from './pages/RoomsPage';
import PicturesPage from './pages/PicturesPage';
import ConceptPage from './pages/ConceptPage';
import MembershipPage from './pages/MembershipPage';
import FaqPage from './pages/FaqPage';
import SuccessPage from './pages/helper-pages/SuccessPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '',
                element: <HomePage/>,
            },
            {
                path: 'contact',
                element: <ContactPage/>,
            },
            {
                path: 'membership',
                element: <MembershipPage/>,
            },
            {
                path: 'concept',
                element: <ConceptPage/>,
            },
            {
                path: 'rooms',
                element: <RoomsPage/>,
            },
            {
                path: 'pictures',
                element: <PicturesPage/>,
            },
            {
                path: 'faq',
                element: <FaqPage/>,
            },
            {
                path: 'success',
                element: <SuccessPage/>,
            },
            {
                path: '*',
                element: <ErrorPage/>,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
