import SquatRackSide from '../assets/images/free-weight-room17-600w.avif'
import GymInsideMedium from '../assets/images/machine-room1-600w.avif'
import MachineRoom from '../assets/images/machine-room3-600w.avif'
import DeadliftStation from '../assets/images/free-weight-room6-600w.avif'
import FreeWeightRoom from '../assets/images/free-weight-room7-600w.avif'
import Machine from '../assets/images/machine-room11-600w.avif'
import FreeWeightBalls from '../assets/images/free-weight-room5-600w.avif'


import BikeImage from '../assets/images/cardio-room12-600w.avif'
import CardioRoom from '../assets/images/cardio-room3-600w.avif'

import { Link } from 'react-router-dom'
import HeadingOne from '../components/HeadingOne';
import HeadingTyping from '../components/HeadingTyping';
import React, { useEffect } from 'react';

const ConceptPage = () => {
    useEffect(() => {
        document.title = `Het Concept Uitgelegd - Private Gym Schijndel`;
    }, []);

    return (
        <>
            <section className="hero concept-hero">
                <div className="default-container flex justify-center items-center h-full w-full">
                    <div className="text-center">
                        <HeadingOne>
                            Het concept uitgelegd.
                        </HeadingOne>
                        <h3 className="text-primary-500 pt-2 pb-16">Alle voordelen van Private Gym Schijndel.</h3>
                    </div>
                </div>
            </section>

            <section>
                <div className="max-w-4xl px-4 mx-4 md:mx-auto my-12 md:my-32 black-card">
                    <h2>Een ruimte voor u alleen.</h2>
                    <hr className="primary-hr mt-2"/>
                    <p className="py-8 text-gray-200">Train exclusief in je eigen privé-trainingsruimte. Hierdoor ervaar
                        jij volledige privacy, geen
                        drukte, nooit wachten, en geen afleiding – alleen jij en je doelen. Ontdek de ruimtes waar jij
                        onbeperkt gebruik van kunt maken.</p>
                    <Link to={'/rooms'} className="primary-button">Bekijk de ruimtes</Link>
                </div>
            </section>

            <section className="bg-black mb-52 pt-12">
                <div className="default-container">
                    <div className="flex flex-col justify-center items-center">
                        <HeadingTyping className="text-3xl md:text-6xl tracking-wider pb-24 text-white">Focus op uw
                            eigen
                            doel.</HeadingTyping>
                        <div className="grid-three-sections -mb-52">
                            <div className="bg-white shadow-2xl rounded">
                                <img className="object-cover h-52 w-full" src={DeadliftStation}
                                     alt="Deadlift ruimte" loading="lazy"/>
                                <div className="p-8">
                                    <h4>Focus op uzelf</h4>
                                    <hr className="primary-hr mb-4"/>
                                    <p>Bij Private Gym Schijndel ervaar je onbeperkt sporten in volledige privacy. Geen
                                        drukte,
                                        geen afleiding - alleen jij en je doelen. Ontdek jouw fitnessjourney, exclusief
                                        en
                                        persoonlijk.</p>
                                </div>
                            </div>

                            <div className="bg-white shadow-2xl rounded">
                                <img className="object-cover h-52 w-full" src={SquatRackSide} alt="Squat rack"
                                     loading="lazy"/>
                                <div className="p-8">
                                    <h4 className="pb-4">Onbeperkt sporten</h4>
                                    <p>Bij Private Gym Schijndel ervaar je onbeperkt sporten in
                                        volledige privacy. Geen drukte, geen afleiding - alleen jij en je doelen. Elke
                                        dag
                                        van het jaar van <span
                                            className="font-bold text-primary-500">7.00 tot 22.00</span> beschikbaar.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white shadow-2xl rounded">
                                <img className="object-cover h-52 w-full" src={GymInsideMedium}
                                     alt="Binnen de gym" loading="lazy"/>
                                <div className="p-8">
                                    <h4 className="pb-4">Persoonlijk trainingsplan</h4>
                                    <p>Bij Private Gym Schijndel sta jij als individu
                                        centraal, samen met jouw doelen. Zo kun je gericht trainen met een plan en
                                        heldere instructies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-20">
                <div className="bg-gradient-to-br md:bg-gradient-to-r from-primary-900 via-primary-500 to-primary-300">
                    <div
                        className="default-container flex flex-col space-y-8 justify-center items-center text-left md:text-center">
                        <h4 className="slogan-title text-3xl md:text-5xl text-black tracking-wide">Bij uw lidmaatschap
                            volgt een <span className="text-primary-50">gratis, verzorgd trainingsschema</span> op maat.
                        </h4>
                        <p className="text-gray-200">Een trainingsschema is bij elk pakket standaard inbegrepen en vergt
                            geen extra kosten.</p>
                    </div>
                </div>
            </section>

            <section className="py-32">
                <div className="small-container">
                    <div
                        className="flex flex-col-reverse md:flex-row justify-center items-center space-x-0 md:space-x-8 h-full">

                        <div className="block md:flex justify-center flex-col space-y-6 w-full md:w-3/5 pr-4">
                            <div className="h-48 w-full shadow-2xl rounded zoom">
                                <img className="object-cover h-full w-full" src={CardioRoom}
                                     alt="Cardioruimte" loading="lazy"/>
                            </div>
                            <div className="h-48 w-full shadow-2xl rounded zoom">
                                <img className="object-cover h-full w-full" src={MachineRoom}
                                     alt="Machinekamer" loading="lazy"/>
                            </div>
                            <div className="h-48 w-full shadow-2xl rounded zoom">
                                <img className="object-cover h-full w-full" src={FreeWeightRoom}
                                     alt="Free weight kamer" loading="lazy"/>
                            </div>
                        </div>
                        <div className="w-full md:w-2/5 pb-12 md:pb-0">
                            <h3>Het beste materiaal.</h3>
                            <p className="pt-4">Bij Private Gym Schijndel staat kwaliteit
                                centraal en dat is te zien in de trainingsmaterialen. Maak gebruik van het beste van het
                                beste
                                materiaal waardoor trainen nog leuker wordt.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-black text-white">
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2>Klaar voor een gezondere leefstijl?</h2>
                    <p className="pt-4 pb-12 text-gray-300">Bekijk welke mogelijkheid
                        het beste bij jou, en mogelijk jouw buddy, past. Want samen trainen verhoogt jouw kans op
                        succes.</p>
                    <Link to={'/membership'} className="primary-button">Bekijk de mogelijkheden</Link>
                </div>
            </section>

            <section className="three-cards-section py-8 md:py-24">
                <div className="default-container flex justify-center items-center">
                    <div
                        className="block md:flex items-center space-y-8 md:space-y-0 space-x-0 md:space-x-4 max-w-4xl w-full">
                        <div className="h-80 w-full md:w-1/3 shadow-2xl rounded zoom">
                            <img className="object-cover h-full w-full" src={FreeWeightBalls} alt="Gewichtsballen"
                                 loading="lazy"/>
                        </div>
                        <div className="h-96 w-full md:w-1/3 shadow-2xl rounded zoom">
                            <img className="object-cover h-full w-full" src={BikeImage} alt="Cardio fiets"
                                 loading="lazy"/>
                        </div>
                        <div className="h-72 w-full md:w-1/3 shadow-2xl rounded zoom">
                            <img className="object-cover h-full w-full" src={Machine} alt="Chest press machine"
                                 loading="lazy"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConceptPage;