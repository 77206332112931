import React, { ReactNode, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

interface HeadingTypingProps {
    children: ReactNode;
    className?: string;
}

const HeadingTyping: React.FC<HeadingTypingProps> = ({children, className}) => {
    const [animationStarted, setAnimationStarted] = useState(false);

    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    const typingAnimation = useSpring({
        transform: animationStarted ? 'translateY(0)' : 'translateY(0px)',
        config: {duration: 1000},
    });

    const [typedText, setTypedText] = useState<string>('');

    useEffect(() => {
        const textToType = children as string;
        let index = 0;

        const typeText = () => {
            if (index < textToType.length) {
                setTypedText(textToType.substring(0, index + 1));
                index++;
            }
        };

        const handleScroll = () => {
            const element = document.getElementById('headingTyping'); // Replace with the actual ID of your component
            if (element) {
                const rect = element.getBoundingClientRect();
                if (!animationStarted && rect.top < window.innerHeight) {
                    setAnimationStarted(true); // Start the animation when the component enters the viewport
                }
            }
        };

        if (!isMobile) {
            const typingInterval = 75;
            const typingIntervalId = setInterval(typeText, typingInterval);

            window.addEventListener('scroll', handleScroll);

            return () => {
                clearInterval(typingIntervalId);
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setTypedText(textToType); // Set the entire text on mobile devices
        }
    }, [children, animationStarted, isMobile]);

    return (
        <animated.h2 id="headingTyping" className={className} style={typingAnimation}>
            {typedText}
        </animated.h2>
    );
};

export default HeadingTyping;
