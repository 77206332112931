import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import FreeWeight1 from '../assets/images/free-weight-room1.jpeg'
import FreeWeight1Small from '../assets/images/free-weight-room1-600w.avif'
import FreeWeight1Medium from '../assets/images/free-weight-room1-1200w.avif'
import FreeWeight1Large from '../assets/images/free-weight-room1-2000w.avif'

import FreeWeight2 from '../assets/images/free-weight-room2.jpeg'
import FreeWeight2Small from '../assets/images/free-weight-room2-600w.avif'
import FreeWeight2Medium from '../assets/images/free-weight-room2-1200w.avif'
import FreeWeight2Large from '../assets/images/free-weight-room2-2000w.avif'

import FreeWeight3 from '../assets/images/free-weight-room3.jpeg'
import FreeWeight3Small from '../assets/images/free-weight-room3-600w.avif'
import FreeWeight3Medium from '../assets/images/free-weight-room3-1200w.avif'
import FreeWeight3Large from '../assets/images/free-weight-room3-2000w.avif'

import Machine1 from '../assets/images/machine-room8.jpeg'
import Machine1Small from '../assets/images/machine-room8-600w.avif'
import Machine1Medium from '../assets/images/machine-room8-1200w.avif'
import Machine1Large from '../assets/images/machine-room8-2000w.avif'

import Machine2 from '../assets/images/machine-room4.jpeg'
import Machine2Small from '../assets/images/machine-room4-600w.avif'
import Machine2Medium from '../assets/images/machine-room4-1200w.avif'
import Machine2Large from '../assets/images/machine-room4-2000w.avif'

import Machine3 from '../assets/images/machine-room3.jpeg'
import Machine3Small from '../assets/images/machine-room3-600w.avif'
import Machine3Medium from '../assets/images/machine-room3-1200w.avif'
import Machine3Large from '../assets/images/machine-room3-2000w.avif'

import Cardio1 from '../assets/images/cardio-room1.jpeg'
import Cardio1Small from '../assets/images/cardio-room1-600w.avif'
import Cardio1Medium from '../assets/images/cardio-room1-1200w.avif'
import Cardio1Large from '../assets/images/cardio-room1-2000w.avif'

import Cardio2 from '../assets/images/cardio-room2.jpeg'
import Cardio2Small from '../assets/images/cardio-room2-600w.avif'
import Cardio2Medium from '../assets/images/cardio-room2-1200w.avif'
import Cardio2Large from '../assets/images/cardio-room2-2000w.avif'

import Cardio3 from '../assets/images/cardio-room3.jpeg'
import Cardio3Small from '../assets/images/cardio-room3-600w.avif'
import Cardio3Medium from '../assets/images/cardio-room3-1200w.avif'
import Cardio3Large from '../assets/images/cardio-room3-2000w.avif'

import { Link } from 'react-router-dom';
import HeadingOne from '../components/HeadingOne';

const RoomsPage = () => {
    useEffect(() => {
        document.title = `De ruimtes - Private Gym Schijndel`;
    }, []);

    return (
        <>
            <section className="hero rooms-hero">
                <div className="default-container flex justify-center items-center h-full w-full">
                    <div className="text-center">
                        <HeadingOne>
                            De beschikbare ruimtes.
                        </HeadingOne>
                        <h3 className="text-primary-500 pt-2 pb-16">Neem een kijkje in de beschikbare ruimtes.</h3>
                    </div>
                </div>
            </section>

            <section id="cardio-room" className="bg-black">
                <div
                    className="default-container text-white">
                    <h2 className="text-3xl md:text-6xl tracking-wider">De Cardioruimte.</h2>
                    <p className="pt-8 text-gray-300">Ontdek onze cardioruimte, waar je je conditie
                        naar nieuwe hoogten kunt stuwen. Met cardio-apparaten bereik je je
                        fitnessdoelen en werk je aan een sterker, energieker lichaam.</p>
                </div>

                <Carousel interval={5000} infiniteLoop emulateTouch showThumbs={false} showStatus={false} autoPlay>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Cardio2}
                            srcSet={`${Cardio2Small} 300w, ${Cardio2Medium} 768w, ${Cardio2Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Cardio ruimte 2"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Cardio1}
                            srcSet={`${Cardio1Small} 300w, ${Cardio1Medium} 768w, ${Cardio1Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Cardio ruimte 2"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Cardio3}
                            srcSet={`${Cardio3Small} 300w, ${Cardio3Medium} 768w, ${Cardio3Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Cardio ruimte 2"
                        />
                    </div>
                </Carousel>
            </section>

            <section id="machine-room">
                <div className="default-container">
                    <h2 className="text-3xl md:text-6xl tracking-wider">De Machinekamer.</h2>
                    <hr className="primary-hr"/>
                    <p className="pt-8">Ontdek onze geavanceerde krachttrainingsruimte, waar je
                        jouw spierkracht naar nieuwe niveaus kunt tillen. Met moderne krachtapparaten doe je
                        jouw spier- en botversterkende oefeingen en werk je aan een sterker, krachtiger
                        lichaam</p>
                </div>

                <Carousel interval={5000} infiniteLoop emulateTouch showThumbs={false} showStatus={false} autoPlay>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Machine1}
                            srcSet={`${Machine1Small} 300w, ${Machine1Medium} 768w, ${Machine1Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Machine ruimte 1"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Machine2}
                            srcSet={`${Machine2Small} 300w, ${Machine2Medium} 768w, ${Machine2Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Machine ruimte 2"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={Machine3}
                            srcSet={`${Machine3Small} 300w, ${Machine3Medium} 768w, ${Machine3Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Machine ruimte 3"
                        />
                    </div>
                </Carousel>
            </section>

            <section id="free-weight-zone" className="bg-black">
                <div
                    className="default-container text-white">
                    <h2 className="text-3xl md:text-6xl tracking-wider">De Free-weight zone.</h2>
                    <p className="pt-8 text-gray-300">Ontdek onze geavanceerde vrije gewichtsruimte, waar je
                        jouw kracht en spierdefinitie naar nieuwe hoogten kunt brengen. Met moderne vrije
                        gewichtsapparatuur voer je spier- en botversterkende oefeningen uit en werk je aan een
                        sterker, krachtiger lichaam.</p>
                </div>

                <Carousel interval={5000} infiniteLoop emulateTouch showThumbs={false} showStatus={false} autoPlay>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={FreeWeight1}
                            srcSet={`${FreeWeight1Small} 300w, ${FreeWeight1Medium} 768w, ${FreeWeight1Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Free weight ruimte 1"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={FreeWeight2}
                            srcSet={`${FreeWeight2Small} 300w, ${FreeWeight2Medium} 768w, ${FreeWeight2Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Free weight ruimte 2"
                        />
                    </div>
                    <div>
                        <img
                            className="carousel-image-room"
                            src={FreeWeight3}
                            srcSet={`${FreeWeight3Small} 300w, ${FreeWeight3Medium} 768w, ${FreeWeight3Large} 1280w`}
                            sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                            alt="Free weight ruimte 3"
                        />
                    </div>
                </Carousel>
            </section>

            <section className="bg-gray-100">
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2>Ervaar zelf de ruimtes.</h2>
                    <p className="pt-4 pb-12">Neem vrijblijvend contact op voor een oriënterend gesprek.</p>
                    <Link to={'/contact'} className="primary-button">Bekijk de mogelijkheden</Link>
                </div>
            </section>
        </>
    )
}

export default RoomsPage;