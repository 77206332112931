import { useEffect } from 'react';

const ErrorPage = () => {
    useEffect(() => {
        document.title = `Error 404 - Private Gym Schijndel`;
    }, []);
    return (
        <section className="bg-black">
            <div className="h-screen px-8 text-center flex flex-col space-y-12 justify-center items-center text-white">
                <h2 className="">Deze pagina bestaat niet.</h2>
                <p className="text-gray-200">De pagina waar u naar zoekt lijkt niet te bestaan, navigeer via het menu
                    naar andere pagina's.</p>
            </div>
        </section>
    )
}

export default ErrorPage;