export interface IMembershipPackage {
    id: number;
    isAdvised: boolean;
    title: string;
    benefits: string[];
    price: number;
    discountPrice?: number;
}

export const membershipPackages: IMembershipPackage[] = [
    {
        id: 0,
        isAdvised: false,
        title: 'Daluur pakket',
        benefits: ['Onbeperkt sporten', 'Privé ruimte naar keuze', 'Persoonlijke trainingsplan', 'Elke dag toegang van 10.00 - 18.00', 'Maandelijks opzegbaar',],
        price: 75,
        discountPrice: 60
    },
    {
        id: 1,
        isAdvised: true,
        title: 'All-in pakket',
        benefits: ['Onbeperkt sporten', 'Privé ruimte naar keuze', 'Persoonlijke trainingsplan', 'Elke dag toegang van 7.00 - 22.00', 'Maandelijks opzegbaar',],
        price: 95,
        discountPrice: 80
    },
    {
        id: 2,
        isAdvised: false,
        title: 'Duo-pakket',
        benefits: ['Voordeliger per persoon', 'Onbeperkt sporten', 'Privé ruimte naar keuze', 'Persoonlijke trainingsplan', 'Elke dag toegang van 7.00 - 22.00', 'Maandelijks opzegbaar',],
        price: 85,
        discountPrice: 70
    },
];