import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadingOne from '../components/HeadingOne';

interface FaqItem {
    question: string;
    answer: string;
}

interface FaqCategory {
    title: string;
    items: FaqItem[];
}

const FaqPage = () => {
    useEffect(() => {
        document.title = `FAQ - Private Gym Schijndel`;
    }, []);

    const faqData: FaqCategory[] = [
        {
            items: [
                {
                    question: 'Waarom zijn de prijzen hoger dan een ‘normale’ sportschool?',
                    answer: 'Bij Private Gym Schijndel is doorgaans duurder dan een normaal fitnessabonnement\n' +
                        'vanwege de privacy die het biedt, exclusieve aandacht en maatwerk. In onze Private Gym\n' +
                        'zijn de faciliteiten van hoogwaardige kwaliteit en krijg je daarnaast een gepersonaliseerd\n' +
                        'trainingsplan. Dit maakt het een premium optie voor diegenen die bereid zijn te\n' +
                        'investeren in hun fitnessdoelen en de voordelen van individualisatie waarderen.',
                },

                {
                    question: 'Als ik niet kan sporten door ziekte of een blessure, kan ik mijn abonnement dan stopzetten?',
                    answer: 'In het geval van ziekte of een blessure kan je je abonnement gewoon stopzetten en\n' +
                        'activeren we hem weer als jij er klaar voor bent.',
                },

            ],
            title: 'Algemeen'
        },
        {
            items: [
                {
                    question: 'Zit ik er een jaar aan vast?',
                    answer: 'Bij Private Gym Schijndel vinden wij flexibiliteit erg belangrijk. Daarom is je\n' +
                        'abonnement maandelijks opzegbaar met één maand opzegtermijn.',
                },
                {
                    question: 'Kan ik mijn abonnement ook delen?',
                    answer: 'Je kunt je abonnement niet delen. Echter kan je wel gebruik maken van een korting als je\n' +
                        'met een familielid of vriend inschrijft.',
                },

            ],
            title: 'Het lidmaatschap'
        }
    ]

    const [openIndices, setOpenIndices] = useState<number[]>([]);

    const toggleAccordion = (index: number) => {
        if (openIndices.includes(index)) {
            // Item is already open, so close it
            setOpenIndices(openIndices.filter((i) => i !== index));
        } else {
            // Item is closed, so open it
            setOpenIndices([...openIndices, index]);
        }
    };

    return (
        <>
            <section className="faq-hero">
                <div className="default-container flex justify-center items-center h-full w-full">
                    <div className="text-center">
                        <HeadingOne>
                            Vaak gestelde vragen
                        </HeadingOne>
                        {/* <hr className="hero-hr" /> */}
                        <h3 className="text-primary-500 pt-2 pb-16">Vragen over de sportschool.</h3>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100">
                <div className="medium-container">

                    {faqData.map((category, categoryIndex) => (
                        <div key={categoryIndex} className="py-4">
                            <h3 className="pb-4">{category.title}</h3>
                            {category.items.map((item, itemIndex) => {
                                const uniqueIndex = categoryIndex * 1000 + itemIndex;
                                return (
                                    <div key={uniqueIndex} className="mb-4">
                                        <button
                                            onClick={() => toggleAccordion(uniqueIndex)}
                                            className="text-left w-full flex justify-between items-center bg-white p-4 md:p-6 rounded hover:bg-gray-50 focus:outline-none shadow-2xl"
                                        >
                                            <span
                                                className="text-lg md:text-xl font-semibold pr-2">{item.question}</span>
                                            <FontAwesomeIcon
                                                className={`text-primary-500 text-2xl transform transition-transform ${
                                                    openIndices.includes(uniqueIndex) ? 'rotate-180' : ''
                                                }`}
                                                icon={faCaretDown}
                                            />
                                        </button>
                                        {openIndices.includes(uniqueIndex) && (
                                            <div className="bg-white p-4 md:p-6 rounded-b shadow-2xl">
                                                <p>{item.answer}</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                    <div>
                        <h3 className="pb-4">Huisregels</h3>
                        <ol className="white-card list-decimal text-base md:text-lg font-normal md:font-semibold">
                            <li>Respecteer elkaars privacy.</li>
                            <li>Laat alles netjes achter. Dat is wel zo fijn voor een ander.</li>
                            <li>Kom enkel in de tijd waarin jij hebt gereserveerd.</li>
                            <li>Maak nadat je klaar bent, het apparaat even schoon.</li>
                        </ol>
                    </div>
                </div>
            </section>

            <section>
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2>Nog overige vragen?</h2>
                    <p className="pt-4 pb-12">Aarzel dan niet op contact met ons op te nemen.</p>
                    <Link className="primary-button" to={'/contact'}>Neem contact op</Link>
                </div>
            </section>
        </>
    )
}

export default FaqPage;